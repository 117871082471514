<template>
  <section class="progress-bar">
    <img src="@/assets/images/progress-bar.svg" alt="" />
  </section>
</template>

<script setup>
import { onUnmounted } from 'vue';

if (!document.body.style.cssText.length) {
  document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    width: 100%;`;
}

const scrollY = document.body.style.top;

onUnmounted(() => {
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
});
</script>

<style scoped>
.progress-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}
.progress-bar img {
  width: 166px;
  height: 152px;
}
</style>
