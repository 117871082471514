<template>
  <section class="alert-mobile">
    <h1 class="alert-mobile__logo sprite sprite--logo">민트프라임</h1>
    <h2 class="alert-mobile__content body-16-sb">현재 페이지는 PC로 사용 가능합니다.</h2>
    <ButtonComponent
      type="btn"
      class="alert-mobile__button"
      title="PC버전"
      @click="emit('unblocking')"
    ></ButtonComponent>
  </section>
</template>

<script setup>
import ButtonComponent from '@/components/ButtonComponent.vue';

import { onUnmounted } from 'vue';

const emit = defineEmits(['unblocking']);

if (!document.body.style.cssText.length) {
  document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    width: 100%;`;
}

const scrollY = document.body.style.top;

onUnmounted(() => {
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
});
</script>

<style scoped>
.alert-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 53px;
  background-color: var(--White);
  z-index: 300;
}
.alert-mobile__button {
  width: 280px;
  color: var(--Gray-30);
}
</style>
