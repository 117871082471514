import { defineStore } from 'pinia';
import { ref } from 'vue';
import * as productApi from '@/api/product';

export const useProductStore = defineStore(
  'product',
  () => {
    const redirectFlag = ref(false);
    const tagList = ref([]);
    const selectedTagList = ref([]);
    const searchedQuery = ref('');
    const productList = ref([]);
    const selectedProductId = ref();
    const product = ref({});

    async function getTagList() {
      selectedTagList.value.length = 0;
      const { data: result } = await productApi.getTagList();

      tagList.value = result.tag_list;
    }

    async function getProductList() {
      const selectedTagStr =
        selectedTagList.value.length > 0 ? selectedTagList.value.toString() : '0';

      const { data: result } = await productApi.getProductList({
        params: { tag_id: selectedTagStr, search_query: searchedQuery.value },
      });

      productList.value = result.product_list;
    }

    async function getProduct(id) {
      const { data: result } = await productApi.getProduct(id);

      product.value = result;

      if (result.size_prices) {
        product.value.size_prices = result.size_prices.reduce(
          (acc, cur) => ({ ...acc, ...cur }),
          {}
        );
      }
      if (result.print_options) {
        product.value.print_options = result.print_options.reduce(
          (acc, cur) => ({ ...acc, ...cur }),
          {}
        );
      }
      if (result.color_discount) {
        product.value.color_discount = result.color_discount.reduce(
          (acc, cur) => ({ ...acc, ...cur }),
          {}
        );
      }
      if (result.quantity_discount) {
        product.value.quantity_discount = result.quantity_discount.reduce(
          (acc, cur) => ({ ...acc, ...cur }),
          {}
        );
      }
    }

    return {
      redirectFlag,
      tagList,
      selectedTagList,
      searchedQuery,
      productList,
      selectedProductId,
      product,
      getTagList,
      getProductList,
      getProduct,
    };
  },
  { persist: true }
);
