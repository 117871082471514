<template>
  <ul v-if="productList.length > 0" class="product-list">
    <li v-for="product in productList" :key="product.id">
      <ProductCardComponent :product="product"></ProductCardComponent>
    </li>
  </ul>
  <div v-else class="product-list product-list--none">
    <p class="head-48-b">검색결과가 없습니다.</p>
  </div>
</template>

<script setup>
import ProductCardComponent from '@/components/ProductCardComponent.vue';

import { useProductStore } from '@/stores/product';
import { storeToRefs } from 'pinia';

const store = useProductStore();

const { productList } = storeToRefs(store);
</script>

<style scoped>
.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 20px;
}
.product-list--home {
  margin-top: 36px;
}
.product-list--products {
  flex: 1;
}
.product-list--none {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-list--home.product-list--none {
  height: 507px;
}
.product-list--none > p {
  color: var(--Gray-30);
}
</style>
