import http from '@/utils/http';

export function getTagList() {
  return http.get('/products/tags/');
}

export function getProductList(query) {
  return http.get('/products/', query);
}

export function getProduct(id) {
  return http.get(`/products/${id}/`);
}
