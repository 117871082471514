import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import filters from './utils/filters';
import validation from './utils/validation';

import vClickOutside from './directives/vClickOutside';

import ProgressBar from './components/ProgressBar.vue';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App).use(pinia).use(router).use(VueCookies);

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$validation = validation;
app.directive('click-outside', vClickOutside);
app.component('ProgressBar', ProgressBar);
app.mount('#app');
