<template>
  <main class="home">
    <section class="home__banner">
      <div class="banner__content">
        <SearchComponent
          class="search--home"
          :legendTitle="'우리 카페에 필요한 제품을 지금 바로 검색해보세요.'"
          :placeholder="'찾으시는 제품을 검색해주세요.'"
          v-model="searchedQuery"
          @update:modelValue="store.getProductList"
          @submit="store.getProductList"
        ></SearchComponent>
        <p class="head-48-b">
          지금 패키지 제작시 <br />
          <span>50만원 상당의 디자인 지원금</span>
        </p>
        <div class="banner__content__btn">
          <ButtonComponent
            class="body-18-b"
            :type="'link'"
            :title="'바로 참여하기'"
            :herf="'/user/login'"
          ></ButtonComponent>
        </div>
      </div>
      <img class="banner__img" src="@/assets/images/home-banner.jpg" alt="banner-img" />
    </section>
    <section class="home__product">
      <div>
        <ProductTagListComponent class="product__tag-list--home"></ProductTagListComponent>
        <ProductListComponent class="product-list--home"></ProductListComponent>
      </div>
    </section>
    <section class="home__support">
      <CustomerSupportCardComponent class="customer-support--home"></CustomerSupportCardComponent>
    </section>
  </main>
</template>

<script setup>
import SearchComponent from '@/components/SearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ProductTagListComponent from '@/components/ProductTagListComponent.vue';
import ProductListComponent from '@/components/ProductListComponent.vue';
import CustomerSupportCardComponent from '@/components/CustomerSupportCardComponent.vue';

import { provide } from 'vue';
import { useRouter } from 'vue-router';
import { useProductStore } from '@/stores/product';
import { storeToRefs } from 'pinia';

const router = useRouter();
const store = useProductStore();

const { searchedQuery } = storeToRefs(store);

searchedQuery.value = '';
store.getTagList();
store.getProductList();

function productClickEvent(id) {
  store.redirectFlag = true;
  store.selectedProductId = id;

  router.push('/products');
}
provide('productClickEvent', productClickEvent);
</script>

<style scoped>
.home__banner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.home__banner .banner__content {
  position: absolute;
  top: max(29.26%, 48px);
  left: 62px;
  z-index: 10;
  color: var(--White);
}
.home__banner .banner__content > p {
  margin-top: 30px;
  margin-left: 7px;
}
.home__banner .banner__content > p > span {
  color: var(--Key);
}
.home__banner .banner__content__btn {
  width: 320px;
  margin-top: 36px;
  margin-left: 7px;
}
.home__banner .banner__content__btn .btn {
  background-color: var(--Key);
  color: var(--Black);
}
.home__banner .banner__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home__product {
  container-name: home__product;
  container-type: inline-size;
  margin: 0 100px;
}
.home__product > div {
  max-width: 1240px;
  margin: 0 auto;
  padding: 46px 0;
  border-bottom: 1px solid var(--Gray-30);
}
.product__tag-list--home {
  margin-left: 7px;
}
.home__support {
  padding: 36px 100px;
}
.customer-support--home {
  max-width: 1240px;
  margin: 0 auto;
}

@media screen and (min-width: 2000px) {
  .home__banner {
    padding-bottom: 42.86%;
  }
}
@container home__product (max-width: 1239px) {
  .home__product > div {
    max-width: 988px;
  }
}
</style>
