<template>
  <footer>
    <router-link to="/" class="header__logo sprite sprite--logo sprite--footer-logo">
      MAiD
    </router-link>
  </footer>
</template>

<script setup></script>

<style scoped>
footer {
  min-height: 181px;
  padding: 36px;
  background-color: var(--Black);
}
</style>
