import http from '@/utils/http';

export function signup(data) {
  return http.post('/users/signup/', data);
}

export function login(data) {
  return http.post('/users/login/', data);
}

export function requestEmailVerification(data) {
  return http.post('/auth/verify/', data);
}

export function verifyEmail(key) {
  return http.get(`/auth/verify/${key}/`);
}

export function verificationCheck() {
  return http.get('/auth/verify/check/');
}

export function updateAccount(data) {
  return http.patch('/users/', data);
}

export function logout(data) {
  return http.post('/users/logout/', data);
}

export function refreshAuth(data) {
  return http.post('/auth/refresh/', data);
}

export function getAccountInfo() {
  return http.get('/users/');
}

export function checkPassword(data) {
  return http.post('/users/check-password/', data);
}

export function resetPassword(data) {
  return http.post('/auth/reset-password/', data);
}

export function deleteAccount() {
  return http.delete('/users/');
}
