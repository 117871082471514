import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import HomeLayout from '../layouts/HomeLayout.vue';
import UserLayout from '../layouts/UserLayout.vue';
import WorkspaceLayout from '../layouts/WorkspaceLayout.vue';
import { useUserStore } from '@/stores/user';

const routes = [
  {
    path: '/',
    meta: { layout: HomeLayout },
    children: [
      {
        path: '',
        name: 'Home',
        meta: { isRequiredUnauth: true },
        component: HomeView,
      },
      {
        path: 'service',
        name: 'Service',
        meta: { isRequiredUnauth: true },
        component: () => import(/* webpackChunkName: "home" */ '../views/ServiceView.vue'),
      },
      {
        path: 'support',
        name: 'Support',
        meta: { isRequiredUnauth: true },
        component: () => import(/* webpackChunkName: "home" */ '../views/CustomerSupportView.vue'),
      },
    ],
  },
  {
    path: '/user',
    meta: { layout: UserLayout },
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: { isRequiredUnauth: true },
        component: () => import(/* webpackChunkName: "user" */ '../views/LoginView.vue'),
      },
      {
        path: 'signup',
        children: [
          {
            path: '',
            name: 'Signup',
            meta: { isRequiredUnauth: true },
            component: () => import(/* webpackChunkName: "user" */ '../views/SignUpView.vue'),
          },
          {
            path: 'process',
            name: 'SignupProcess',
            meta: { isRequiredAuth: true },
            component: () =>
              import(/* webpackChunkName: "user" */ '../views/SignUpProcessView.vue'),
          },
        ],
      },
      {
        path: 'find',
        name: 'FindPassword',
        meta: { isRequiredUnauth: true },
        component: () => import(/* webpackChunkName: "user" */ '../views/FindPasswordView.vue'),
      },
    ],
  },
  {
    path: '/user/sns/login-callback',
    name: 'SnsLoginCallback',
    meta: { isRequestSnsLogin: true },
    component: () => import(/* webpackChunkName: "user" */ '../views/SnsLoginCallbackView.vue'),
  },
  {
    path: '/',
    meta: { layout: WorkspaceLayout },
    children: [
      {
        path: '/projects',
        name: 'Projects',
        meta: { isRequiredAuth: true },
        component: () => import(/* webpackChunkName: "service" */ '../views/ProjectsView.vue'),
      },
      {
        path: '/products',
        name: 'products',
        meta: { isRequiredAuth: true },
        component: () => import(/* webpackChunkName: "service" */ '../views/ProductsView.vue'),
      },
      {
        path: '/mypage',
        name: 'MyPage',
        meta: { isRequiredAuth: true },
        component: () => import(/* webpackChunkName: "service" */ '../views/MyPageView.vue'),
      },
    ],
  },
  {
    path: '/projects/:id',
    name: 'Project',
    meta: { isRequiredAuth: true },
    component: () => import(/* webpackChunkName: "project" */ '../views/ProjectView.vue'),
  },
  {
    path: '/user/verify/:key',
    name: 'Verify',
    component: () => import(/* webpackChunkName: "verify" */ '../views/EmailVerifyView.vue'),
  },
  {
    path: '/projects/shared/:id',
    name: 'SharedProject',
    meta: { isRequiredAuth: true },
    component: () => import(/* webpackChunkName: "shared" */ '../views/SharedProjectView.vue'),
  },
  {
    path: '/500',
    name: 'ServerError',
    component: () => import(/* webpackChunkName: "error" */ '../views/ServerErrorView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "error" */ '../views/NotFoundView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const store = useUserStore();

  if (to.path === '/user/signup' && from.path === '/user/signup/process') {
    return next(false);
  }
  if (to.matched.some((routeInfo) => routeInfo.meta.isRequiredAuth)) {
    if (!store.isLogined) {
      return next({ path: '/user/login', state: { redirect: to.fullPath } });
    }
    if (to.path !== '/user/signup/process') {
      if (!store.isVerified || store.userName === null) {
        return next('/user/signup/process');
      }
    }
  }
  if (to.path === '/user/signup/process') {
    if (store.isLogined && store.isVerified && store.userName !== null) {
      return next('/projects');
    }
  }
  if (to.matched.some((routeInfo) => routeInfo.meta.isRequiredUnauth)) {
    if (store.isLogined) {
      return next('/projects');
    }
  }
  if (to.matched.some((routeInfo) => routeInfo.meta.isRequestSnsLogin)) {
    if (!store.requestSnsLoginFlag) {
      return next('/404');
    }
  }

  return next();
});

export default router;
