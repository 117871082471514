import { useEventListener } from '@vueuse/core';

const vClickOutside = {
  beforeMount(el, binding) {
    el.eventClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    useEventListener(document.body, 'click', el.eventClickOutside, { capture: true });
  },
};

export default vClickOutside;
