<template>
  <component v-if="route.meta.layout" :is="route.meta.layout">
    <router-view></router-view>
  </component>
  <template v-else>
    <router-view></router-view>
  </template>
  <alert-mobile v-if="isblocked" @unblocking="unblocking"></alert-mobile>
</template>

<script setup>
import '@/assets/stylesheets/reset.css';
import '@/assets/stylesheets/common.css';
import AlertMobile from '@/components/AlertMobile.vue';

import { ref, onBeforeMount } from 'vue';
import { useEventListener } from '@vueuse/core';
import { useRoute } from 'vue-router';

const route = useRoute();

const isMobile = ref(false);
const isblocked = ref(false);

onBeforeMount(() => {
  if ('maxTouchPoints' in navigator) {
    isMobile.value = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    isMobile.value = navigator.msMaxTouchPoints > 0;
  } else if (window.matchMedia('(pointer:coarse)').matches) {
    isMobile.value = true;
  } else if (window.matchMedia('(any-pointer:coarse)').matches) {
    isMobile.value = true;
  }
  if (isMobile.value) {
    check2Block();
    useEventListener(window, 'resize', check2Block);
  }
});

function check2Block() {
  if (window.innerWidth < 640) {
    isblocked.value = true;
    return;
  }

  isblocked.value = false;
}

function unblocking() {
  isblocked.value = false;

  const viewportContent = 'width=1200px';
  document.querySelector("meta[name='viewport']").setAttribute('content', viewportContent);
}
</script>
