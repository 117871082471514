<template>
  <SidebarComponent></SidebarComponent>
  <slot></slot>
</template>

<script setup>
import SidebarComponent from '@/components/SidebarComponent.vue';

import { onMounted, onUnmounted } from 'vue';

onMounted(async () => {
  document.body.classList.add('workspace');
});

onUnmounted(() => {
  document.body.classList.remove('workspace');
});
</script>

<style>
.workspace #app {
  display: flex;
  flex-direction: row;
}
.workspace main {
  flex: 1;
  padding: 45px 25px;
  background-color: var(--Workspace);
}
</style>
