<template>
  <main class="user">
    <div :class="['user__content', `user__content--${route.name}`]">
      <div>
        <div>
          <router-link to="/" class="sprite sprite--logo">MintPrime</router-link>
        </div>
        <slot></slot>
      </div>
    </div>
    <div class="user__img">
      <img src="@/assets/images/user-background-img.jpg" alt="user-background-img" />
    </div>
  </main>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
}
main {
  flex: 1;
}
</style>

<style scoped>
main {
  display: flex;
}
main > div {
  flex: 1;
}
.user__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 96px;
}
.user__content > div {
  width: 320px;
}
</style>
